body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto Slab', serif;
}
#root {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.App {
  border: 5px solid darkred;
  width: 90%;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px;
}

footer {
  text-align: center;
  font-size: 10px;
  padding: 5px;
  color: darkgrey;
}
input {
  width: 500px;
  border-radius: 2px;
  padding: 5px;
  outline: none;
  border: 1px solid darkgrey;
}
input:active, input:focus {
  border: 1px solid black;
}
input:hover {
  background-color: rgba(0,0,0,.02);
}
.flex {
  display: flex;
}
p{
  margin: 5px 0;
}
table {
  border-collapse: collapse;
}
table td {
  padding: 2px 10px;
}
tr{
  border: 2px solid white;
  border-left: none;
  border-right: none;
}
.center {text-align: center}
.strike {
  text-decoration: line-through;
  font-size: 5px;
}